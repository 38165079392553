import {IoIosRemoveCircleOutline} from "react-icons/io";
import {Text} from "@chakra-ui/react";

interface SectionRemoveProps {
  content: string;
  onRemove: (value: string) => void;
}

function SectionRemove({content, onRemove}: SectionRemoveProps) {

  return (
    <div style={{display: "flex", alignItems: "center", userSelect: "none"}}>
      <IoIosRemoveCircleOutline style={{marginRight: "5px"}} color={"#d00037"} onClick={() => onRemove(content)}/>
      <Text>{content}</Text>
    </div>
  )
}

export default SectionRemove