import {IoIosAddCircleOutline} from "react-icons/io";
import {Text} from "@chakra-ui/react";

interface SectionAddProps {
  content: string;
  onAdd: (value: string) => void;
}

function SectionAdd({content, onAdd}: SectionAddProps) {

  return (
    <div style={{display: "flex", alignItems: "center", marginLeft: "10px", userSelect: "none"}} onClick={() => onAdd(content)}>
      <IoIosAddCircleOutline style={{marginRight: "5px"}} color={"#0055d0"}/>
      <Text color={"#0055d0"}>{content}</Text>
    </div>
  )
}

export default SectionAdd