import {Select, styled, Text} from "@chakra-ui/react";

const Section = styled("div", {
  baseStyle: {
    display: "flex",
    alignItems: "center",
  }
})

interface SectionChoiceProps {
  beforeChoice: string;
  afterChoice: string;
  onChange: (value: string) => void;
  choices: string[];
  width?: string;
  marginLeft?: string;
  marginRight?: string;
  defaultValue?: string;
}

function SectionChoice({
                         beforeChoice,
                         afterChoice,
                         onChange: setValue,
                         choices,
                         width,
                         marginRight,
                         marginLeft,
                         defaultValue
                       }: SectionChoiceProps) {

  return (
    <Section>
      <Text>{beforeChoice}</Text>
      <Select
        defaultValue={defaultValue}
        onChange={(event) => setValue(event.target.value)}
        width={width || "auto"}
        height="auto"
        flexWrap="wrap"
        textAlign="center"
        textOverflow={"ellipsis"}
        marginLeft={marginLeft || 2}
        marginRight={marginRight || 2}
        borderColor="transparent"
        _hover={{
          borderColor: "transparent",
        }}
      >
        {choices.map((choice) => <option value={choice} key={choice}>{choice}</option>)}
      </Select>
      <Text>{afterChoice}</Text>
    </Section>
  )
}

export default SectionChoice