import {ChakraProvider} from "@chakra-ui/react";
import {RouterProvider} from "react-router-dom";
import {dashboardRouter} from "./router/Router";

export function Error404() {
  return (
    <div>
      404
    </div>
  )
}

function App() {

  return (
    <ChakraProvider>
      <RouterProvider router={dashboardRouter} fallbackElement={<Error404/>}/>
    </ChakraProvider>
  )
}

export default App;
