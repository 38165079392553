import {Text, styled, EditablePreview, EditableInput, EditableTextarea, Editable} from "@chakra-ui/react";
import MataLogo from "../assets/img/mata-white.svg";
import {useState} from "react";
import SimpleSection from "../components/SimpleSection";
import SectionChoice from "../components/SectionChoice";
import SectionNumberInput from "../components/SectionNumberInput";
import Diameters from "../components/Diameters";
import SectionAdd from "../components/SectionAdd";
import SectionRemove from "../components/SectionRemove";

const A4Page = styled("div", {
  baseStyle: {
    display: "flex",
    flexDirection: "column",
    width: "210mm",
    //height: "297mm",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.3)",
    margin: "20px auto",
    padding: "20mm",
  }
})

const Header = styled("div", {
  baseStyle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    userSelect: "none",
  }
})

const HeaderTitle = styled("h1", {
  baseStyle: {
    fontSize: "20px",
    fontWeight: "bold",
    fontFamily: "DM Sans",
    color: "#0135E1",
  }
})

const Title = styled("h1", {
  baseStyle: {
    fontSize: "20px",
    fontWeight: "bold",
    fontFamily: "DM Sans",
    color: "#000000",
    marginTop: "20px",
    marginBottom: "10px",
  }
})

const SubTitle = styled("h2", {
  baseStyle: {
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "DM Sans",
    color: "#000000",
  }
})

const SectionWrapper = styled("div", {
  baseStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    flexWrap: "wrap",
  }
})

const WhiteSpacer = styled("div", {
  baseStyle: {
    height: "25px",
  }
})

const LightWhiteSpacer = styled("div", {
  baseStyle: {
    height: "15px",
  }
})

function Home() {

  const [description, setDescription] = useState("Decription");

  const acquisitionChoices = ["Prospectif", "Rétrospectif"];
  const [acquisition, setAcquisition] = useState(acquisitionChoices[0]);

  const injectionChoices = ["biphasique", "triphasique"];
  const [injection, setInjection] = useState(injectionChoices[0]);
  const [injectionVolume, setInjectionVolume] = useState("80");
  const [injectionConcentration, setInjectionConcentration] = useState("370");

  const [leftAuricle, setLeftAuricle] = useState("Absence");
  const [atriumVolume, setAtriumVolume] = useState("80");

  const [atrialThrombus, setAtrialThrombus] = useState("Absence");

  const [pulmonaryVein, setPulmonaryVein] = useState("Oui");
  const [pulmonaryVeinDescription, setPulmonaryVeinDescription] = useState("Donner des détails");

  const [diameterSuperiorRightFirst, setDiameterSuperiorRightFirst] = useState("0");
  const [diameterSuperiorRightSecond, setDiameterSuperiorRightSecond] = useState("0");

  const [diameterInferiorRightFirst, setDiameterInferiorRightFirst] = useState("0");
  const [diameterInferiorRightSecond, setDiameterInferiorRightSecond] = useState("0");

  const [diameterSuperiorLeftFirst, setDiameterSuperiorLeftFirst] = useState("0");
  const [diameterSuperiorLeftSecond, setDiameterSuperiorLeftSecond] = useState("0");

  const [diameterInferiorLeftFirst, setDiameterInferiorLeftFirst] = useState("0");
  const [diameterInferiorLeftSecond, setDiameterInferiorLeftSecond] = useState("0");

  const [diameters, setDiameters] = useState<string[]>([]);

  const onAddDiameter = (value: string) => {

    if (diameters.find((element) => element === value)) {
      return;
    }

    setDiameters([...diameters, value]);
  }

  const onRemoveDiameter = (value: string) => {
    setDiameters(diameters.filter((element) => element !== value));
  }

  const [interatrialSeptum, setInteratrialSeptum] = useState<string[]>([]);

  const onAddInteratrialSeptum = (value: string) => {

    if (interatrialSeptum.find((element) => element === value)) {
      return;
    }

    setInteratrialSeptum([...interatrialSeptum, value]);
  }

  const onRemoveInteratrialSeptum = (value: string) => {
    setInteratrialSeptum(interatrialSeptum.filter((element) => element !== value));
  }

  const [stenosis, setStenosis] = useState<string[]>(["Absence de sténose"]);

  const onAddStenosis = (value: string) => {
    setStenosis([value]);
  }

  const onRemoveStenosis = (value: string) => {
    setStenosis([]);
  }

  const [options, setOptions] = useState<string[]>([]);

  const onAddOption = (value: string) => {
    setOptions([...options, value]);
  }

  const onRemoveOption = (value: string) => {
    setOptions(options.filter((element) => element !== value));
  }

  const [moveExam, setMoveExam] = useState<string[]>([]);

  const onAddMoveExam = (value: string) => {

    if (moveExam.find((element) => element === value)) {
      return;
    }

    setMoveExam([...moveExam, value]);
  }

  const onRemoveMoveExam = (value: string) => {
    setMoveExam(moveExam.filter((element) => element !== value));
  }

  const [conclusion, setConclusion] = useState("Pas de thrombus, anatomie modale");

  return (
    <A4Page>
      <Header>
        <img src={MataLogo} alt="Mata Logo" style={{width: "140px", userSelect: "none"}}/>
        <HeaderTitle>Compte rendu interactif</HeaderTitle>
      </Header>
      <SimpleSection title="CT Atrial" defaultValue={description} onChange={(value) => setDescription(value)}
                     type="textarea"/>
      <SimpleSection title="Indication :" defaultValue="Bilan avant ablation de FA"
                     onChange={(value) => setDescription(value)} type="textarea"/>

      <Title>Technique :</Title>
      <SectionChoice beforeChoice="Acquisition en mode synchronisé" afterChoice="à l'ECG" defaultValue={acquisition}
                     onChange={(value) => setAcquisition(value)} choices={acquisitionChoices}/>

      <Text>Au temps artériel et veineux (90s post injection)</Text>

      <SectionWrapper>
        <SectionChoice beforeChoice="Injection" afterChoice="" defaultValue={injection}
                       onChange={(value) => setInjection(value)} choices={injectionChoices}/>
        <Text>de</Text>
        <SectionNumberInput number={injectionVolume} setNumber={setInjectionVolume}/>
        <Text>cc de produit de contraste iodé</Text>
        <SectionNumberInput number={injectionConcentration} setNumber={setInjectionConcentration}/>
        <Text>mg/ml.</Text>
      </SectionWrapper>

      <Title>Résultat :</Title>
      <Text>Auricule gauche</Text>
      <SectionChoice beforeChoice="" afterChoice="de thrombus" marginLeft="-15px" defaultValue={leftAuricle}
                     onChange={(value) => setLeftAuricle(value)} choices={["Absence", "Présence"]}/>

      <WhiteSpacer/>

      <Text>Atrium gauche</Text>
      <SectionWrapper>
        <Text>Volume de l’atrium gauche mesuré à =</Text>
        <SectionNumberInput number={atriumVolume} setNumber={setAtriumVolume}/>
        <Text>ml.</Text>
      </SectionWrapper>
      <SectionChoice beforeChoice="" afterChoice="d’un thrombus atrial" marginLeft="-15px" defaultValue={atrialThrombus}
                     onChange={(value) => setAtrialThrombus(value)} choices={["Absence", "Présence"]}/>

      <WhiteSpacer/>

      <Text>Veine pulmonaire</Text>
      <SectionChoice beforeChoice="Anatomie modale" afterChoice="(deux veine drt et deux veine à gch)"
                     defaultValue={pulmonaryVein}
                     onChange={(value) => setPulmonaryVein(value)} choices={["Oui", "Non"]}/>

      <LightWhiteSpacer/>

      {pulmonaryVein === "Oui" && (
        <div>
          <Diameters textBefore="Diamètres ostial=" textAfter="mm de la veine supérieur droite"
                     firstDiameter={diameterSuperiorRightFirst} secondDiameter={diameterSuperiorRightSecond}
                     setFirstDiameter={setDiameterSuperiorRightFirst}
                     setSecondDiameter={setDiameterSuperiorRightSecond}/>
          <Diameters textBefore="Diamètres ostial=" textAfter="mm de la veine inférieur droite"
                     firstDiameter={diameterInferiorRightFirst} secondDiameter={diameterInferiorRightSecond}
                     setFirstDiameter={setDiameterInferiorRightFirst}
                     setSecondDiameter={setDiameterInferiorRightSecond}/>
          <Diameters textBefore="Diamètres ostial=" textAfter="mm de la veine supérieur gauche"
                     firstDiameter={diameterSuperiorLeftFirst} secondDiameter={diameterSuperiorLeftSecond}
                     setFirstDiameter={setDiameterSuperiorLeftFirst}
                     setSecondDiameter={setDiameterSuperiorLeftSecond}/>
          <Diameters textBefore="Diamètres ostial=" textAfter="mm de la veine inférieur gauche"
                     firstDiameter={diameterInferiorLeftFirst} secondDiameter={diameterInferiorLeftSecond}
                     setFirstDiameter={setDiameterInferiorLeftFirst}
                     setSecondDiameter={setDiameterInferiorLeftSecond}/>
        </div>
      )}

      {pulmonaryVein === "Non" && (
        <Editable defaultValue={pulmonaryVeinDescription} onChange={(value) => setPulmonaryVeinDescription(value)}
                  textColor={"#575757"}>
          <EditablePreview/>
          <EditableTextarea/>
        </Editable>
      )}

      <WhiteSpacer/>

      {diameters.length > 0 && (
        <div>
          {diameters.map((value) => (
            <SectionRemove content={value} onRemove={onRemoveDiameter}/>
          ))}
        </div>
      )}

      {!diameters.find((value) => value === "Veines surnuméraire localisation et diamètres") && (
        <SectionAdd content="Veines surnuméraire localisation et diamètres" onAdd={onAddDiameter}/>
      )}

      {!diameters.find((value) => value === "Présence d’un collecteur commun") && (
        <SectionAdd content="Présence d’un collecteur commun" onAdd={onAddDiameter}/>
      )}

      {!diameters.find((value) => value === "Présence de diverticule atrial") && (
        <SectionAdd content="Présence de diverticule atrial" onAdd={onAddDiameter}/>
      )}

      <WhiteSpacer/>

      <Text>Septum interatrial</Text>

      {interatrialSeptum.length === 0 && (
        <>
          <Text>Aucune anomalie, normal</Text>
        </>
      )}

      {interatrialSeptum.length > 0 && (
        <div>
          {interatrialSeptum.map((value) => (
            <SectionRemove content={value} onRemove={onRemoveInteratrialSeptum}/>
          ))}
        </div>
      )}

      <LightWhiteSpacer/>

      {!interatrialSeptum.find((value) => value === "Présence de fop") && (
        <SectionAdd content="Présence de fop" onAdd={onAddInteratrialSeptum}/>
      )}

      {!interatrialSeptum.find((value) => value === "Présence d'un anévrisme du foramen ovale") && (
        <SectionAdd content="Présence d'un anévrisme du foramen ovale" onAdd={onAddInteratrialSeptum}/>
      )}

      {!interatrialSeptum.find((value) => value === "Présence d'un CIA") && (
        <SectionAdd content="Présence d'un CIA" onAdd={onAddInteratrialSeptum}/>
      )}

      {!interatrialSeptum.find((value) => value === "Présence d'une hypertrophie lipomateuse du septum inter atrial") && (
        <SectionAdd content="Présence d'une hypertrophie lipomateuse du septum inter atrial"
                    onAdd={onAddInteratrialSeptum}/>
      )}

      <WhiteSpacer/>

      {stenosis.length > 0 && (
        <div>
          {stenosis.map((value) => (
            <SectionRemove content={value} onRemove={onRemoveStenosis}/>
          ))}
        </div>
      )}

      {!stenosis.find((value) => value === "Absence de sténose") && (
        <SectionAdd content="Absence de sténose" onAdd={onAddStenosis}/>
      )}

      {!stenosis.find((value) => value === "Rechercher une sténose de la veine cave inférieur ou la présence d’une valve d’Eustachi") && (
        <SectionAdd content="Rechercher une sténose de la veine cave inférieur ou la présence d’une valve d’Eustachi"
                    onAdd={onAddStenosis}/>
      )}

      <WhiteSpacer/>

      {options.length > 0 && (
        <Text>Optionnel</Text>
      )}

      {options.length > 0 && (
        <div>
          {options.map((value) => (
            <SectionRemove content={value} onRemove={onRemoveOption}/>
          ))}
        </div>
      )}

      {!options.find((value) => value === "Recherche d’une anomalie des coronaires.") && (
        <SectionAdd content="Recherche d’une anomalie des coronaires." onAdd={onAddOption}/>
      )}

      <WhiteSpacer/>

      {moveExam.length > 0 && (
        <div>
          {moveExam.map((value) => (
            <SectionRemove content={value} onRemove={onRemoveMoveExam}/>
          ))}
        </div>
      )}

      {!moveExam.find((value) => value === "Produire des reconstruction VR en vue postérieur de l’oreillette gauche.") && (
        <SectionAdd content="Produire des reconstruction VR en vue postérieur de l’oreillette gauche."
                    onAdd={onAddMoveExam}/>
      )}

      <WhiteSpacer/>

      <SimpleSection title="Conclusion :" defaultValue={conclusion}
                     onChange={(value) => setConclusion(value)} type="textarea"/>

      <Title>Signature</Title>

    </A4Page>
  )
}

export default Home;