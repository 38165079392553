import {Editable, EditableInput, EditablePreview, EditableTextarea, styled} from "@chakra-ui/react";

const Title = styled("h1", {
  baseStyle: {
    fontSize: "20px",
    fontWeight: "bold",
    fontFamily: "DM Sans",
    color: "#000000",
    marginTop: "20px",
  }
})

interface SimpleSectionProps {
  title: string;
  defaultValue: string;
  onChange: (value: string) => void;
  type: "text" | "textarea";
}

function SimpleSection({title, defaultValue, onChange: setValue, type}: SimpleSectionProps) {

  return (
    <>
      <Title>{title}</Title>
      <Editable
        defaultValue={defaultValue}
        onChange={(value) => setValue(value)}
        textColor={"#575757"}
        width="auto"
        height="auto"
        maxWidth="100%"
        maxHeight="100%"
        marginTop={2}
        marginBottom={2}
        borderColor="transparent"
        _hover={{
          borderColor: "transparent",
        }}
      >
        <EditablePreview/>
        {type === "text" && <EditableInput/>}
        {type === "textarea" && <EditableTextarea/>}
      </Editable>
    </>
  )
}

export default SimpleSection