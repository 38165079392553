import SectionNumberInput from "./SectionNumberInput";
import {Text} from "@chakra-ui/react";

interface DiametersProps {
  textBefore: string;
  textAfter: string;
  firstDiameter: string;
  secondDiameter: string;
  setFirstDiameter: (value: string) => void;
  setSecondDiameter: (value: string) => void;
}

function Diameters({textBefore, textAfter, firstDiameter, secondDiameter, setFirstDiameter, setSecondDiameter}: DiametersProps) {
  return (
    <div style={{display: "flex", alignItems: "center"}}>
      <Text>{textBefore}</Text>
      <SectionNumberInput number={firstDiameter} setNumber={setFirstDiameter}/>
      <Text>x</Text>
      <SectionNumberInput number={secondDiameter} setNumber={setSecondDiameter}/>
      <Text>{textAfter}</Text>
    </div>
  );
}

export default Diameters;