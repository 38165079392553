import {Editable, EditableInput, EditablePreview, EditableTextarea} from "@chakra-ui/react";

interface SectionNumberInputProps {
  number: string;
  setNumber: (value: string) => void;
}

function SectionNumberInput({number, setNumber}: SectionNumberInputProps) {

  return (
    <Editable defaultValue={number} onChange={(value) => setNumber(value)} width="auto" maxWidth="50px"
              marginLeft={2} marginRight={2}>
      <EditablePreview/>
      <EditableInput/>
    </Editable>
  )
}

export default SectionNumberInput